import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import AuthService from '@/services/AuthService';
import { IResponse } from '../response.interface';

const storedUser = localStorage.getItem('user');

@Module({ namespaced: true })
class Auth extends VuexModule {
  public status = storedUser ? { loggedIn: true } : { loggedIn: false };
  public user = storedUser ? JSON.parse(storedUser) : null;

  @Mutation
  public loginSuccess(user: any): void {
    this.status.loggedIn = true;
    this.user = user;
  }

  @Mutation
  public loginFailure(): void {
    this.status.loggedIn = false;
    this.user = null;
  }

  @Mutation
  public logout(): void {
    this.status.loggedIn = false;
    this.user = null;
    localStorage.removeItem('user');
  }

  @Mutation
  public registerSuccess(): void {
    this.status.loggedIn = false;
  }

  @Mutation
  public registerFailure(): void {
    this.status.loggedIn = false;
  }

  @Action({ rawError: true })
  async login(data: any): Promise<IResponse> {
    let errorMessage;
    const user = await AuthService.login(data.email, data.password).catch(
      error => {
        errorMessage = error?.response?.data?.message || error.toString();
        this.context.commit('loginFailure');
      },
    );

    if (user) {
      this.context.commit('loginSuccess', user);
      return { succeeded: true, data: { userId: user.id } };
    }
    return { succeeded: false, errorMessage };
  }

  @Action
  signOut(): void {
    AuthService.logout();
    this.context.commit('logout');
  }

  @Action
  async reset(data: { token: string; password: string }): Promise<void> {
    await AuthService.resetPassword(data.token, data.password);
  }

  @Action({ rawError: true })
  async register(data: any): Promise<IResponse> {
    let errorMessage;
    const registered = await AuthService.register(
      data.firstname,
      data.lastname,
      data.email,
      data.password,
    ).catch(error => {
      errorMessage = error?.response?.data?.message || error.toString();
      this.context.commit('registerFailure');
    });
    if (registered) {
      this.context.commit('registerSuccess');
      return { succeeded: true };
    }
    return { succeeded: false, errorMessage };
  }

  @Action({ rawError: true })
  async confirm(token: string): Promise<IResponse> {
    const succeeded = await AuthService.confirm(token).catch(error => {
      return false;
    });
    return { succeeded };
  }

  get isLoggedIn(): boolean {
    return this.status.loggedIn;
  }

  @Action({ rawError: true })
  async checkLogin(): Promise<boolean> {
    if (
      this.status.loggedIn &&
      new Date(this.user?.exp).getTime() > new Date().getTime()
    ) {
      return true;
    }
    this.context.commit('logout');
    return false;
  }
}

export default Auth;
