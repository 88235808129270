























































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Dsgvo extends Vue {
  private show: boolean = false;
  private accepted: boolean = false;

  showModal() {
    this.show=true;
  }

  handleAccept() {
    this.show = false;
    this.accepted = true;
    this.$emit('accepted', true);
  }
}
