









































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component
export default class App extends Vue {

  private drawer:boolean = false;

  @Auth.State("user")
  private currentUser!: any;

  @Auth.Action
  private signOut!: () => void;

  get background() {
    return { 
      backgroundImage: `url(${require('./assets/background.jpg')})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    };
  }

  get isAdmin(): boolean {
    if (this.currentUser && this.currentUser.roles) {
      return this.currentUser.roles.includes("admin");
    }

    return false;
  }

  logOut() {
    this.signOut();
    this.$router.push('/login');
  }

  goTo(path: string) {
    this.$router.push(`/${path}`)
  }

  isCurrentPath(path: string):boolean {
    return this.$router.currentRoute.path.includes(path);
  }

}
