import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import UserService from '@/services/UserService';

@Module({ namespaced: true })
class UserModule extends VuexModule {
  public users: any = [];

  @Mutation
  setUsers(users: any) {
    this.users = users;
  }

  @Action({ rawError: true })
  async loadUsers(): Promise<void> {
    const users = await UserService.getUsers();
    this.context.commit('setUsers', users);
  }

  @Action({ rawError: true })
  async deleteUser(id: string): Promise<void> {
    await UserService.deleteUser(id);
    await this.context.dispatch('loadUsers');
  }

  @Action({ rawError: true })
  async updateUser(user: any): Promise<void> {
    await UserService.updateUser(user);
    await this.context.dispatch('loadUsers');
  }
}

export default UserModule;
