import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/components/Home.vue';
import Login from '@/components/Login.vue';
import Register from '@/components/Register/Register.vue';
import Confirm from '@/components/Confirm.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: Confirm,
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('./components/Profile.vue'),
  },
  {
    path: '/user-administration',
    name: 'user-administration',
    component: () =>
      import('./components/UserAdministration/UserAdministration.vue'),
  },
  {
    path: '/edit-offer/:id',
    name: 'edit-offer',
    component: () => import('./components/Offer/EditOffer.vue'),
  },
  {
    path: '/edit-offer',
    name: 'edit-offer',
    component: () => import('./components/Offer/EditOffer.vue'),
  },
  {
    path: '/search-offer',
    name: 'search-offer',
    component: () => import('./components/Offer/OfferGallery.vue'),
  },
  {
    path: '/show-offer/:id',
    name: 'show-offer',
    component: () => import('./components/Offer/ShowOffer.vue'),
  },
  {
    path: '/password-reset/:token',
    name: 'password-reset',
    component: () => import('./components/PasswordReset.vue'),
  },
  {
    path: '/chats',
    name: 'chat-dasbboard',
    component: () => import('./components/Message/ChatDashboard.vue'),
  },
  {
    path: '/chat/:id',
    name: 'chat',
    component: () => import('./components/Message/Chat.vue'),
  },
  // {
  //   path: '/debug',
  //   name: 'debug',
  //   component: () =>
  //     import('./components/LocationSelector/LocationSelectorMap.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/',
    '/login',
    '/register',
    '/home',
    '/confirm',
    // '/debug',
  ];
  const adminPages = ['/user-administration'];
  const authRequired = !publicPages.includes(to.path);
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) : null;
  const isAdmin = user?.roles?.includes('admin');
  const expired = user && new Date(user?.exp).getTime() < new Date().getTime();

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && expired) {
    next('/login');
  }

  if (!isAdmin && adminPages.includes(to.path)) {
    next('/');
  }
  next();
});

export default router;
