import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import OfferService from '@/services/OfferService';

@Module({ namespaced: true })
class OfferGalleryModule extends VuexModule {
  public offers: any = [];

  public filters: any = [];

  @Mutation
  setOffers(offers: any) {
    this.offers = offers;
  }

  @Action({ rawError: true })
  async loadOffers(filter: any): Promise<void> {
    const offers = await OfferService.getOffers(filter);
    this.context.commit('setOffers', offers);
  }
}

export default OfferGalleryModule;
