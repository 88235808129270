




















import { IResponse } from "@/store/response.interface";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component
export default class Confirm extends Vue {
  private loading: boolean = false;
  private message: string = 'Wait for confirmation...';

  @Auth.Action
  private confirm!: (token: string) => Promise<IResponse>;

  async created() {
    this.loading = true;
    const token = String(this.$route.query.token);
    if((await this.confirm(token)).succeeded) {
      this.$router.push("/login");
    } else {
      this.message = 'Token is not valid. Please Login to resend confirmation mail.'
    }
    this.loading = false;
  }
}
