import { apiConfig } from '@/config/api.config';
import axios from 'axios';

const API_URL = `${apiConfig.host}api/auth/`;

class AuthService {
  async login(email: string, password: string) {
    return axios
      .post(API_URL + 'signin', {
        email,
        password,
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  async resetPassword(token: string, password: string): Promise<boolean> {
    const response = await axios.post(API_URL + 'pwd-reset', {
      token,
      password,
    });
    return response?.status === 200;
  }

  async requestPasswordReset(email: string): Promise<boolean> {
    const response = await axios.post(API_URL + 'request-pwd-reset', {
      email,
    });
    return response?.status === 200;
  }

  async register(
    firstname: string,
    lastname: string,
    email: string,
    password: string,
  ): Promise<boolean> {
    const response = await axios.post(API_URL + 'signup', {
      firstname,
      lastname,
      email,
      password,
    });
    return response?.status === 201;
  }

  async confirm(token: string): Promise<boolean> {
    const response = await axios.get(API_URL + `confirm?token=${token}`);
    return Boolean(response.data);
  }
}

export default new AuthService();
