import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import ChatService from '@/services/ChatService';
import { namespace } from 'vuex-class';
const UserModule = namespace('UserModule');

@Module({ namespaced: true })
class ChatModule extends VuexModule {
  public chats: any = [];
  public offerMap = new Map<string, string>();

  @Mutation
  setChats(chats: any) {
    this.chats = chats;
    this.offerMap = new Map<string, string>();
    chats.forEach((chat: any) => this.offerMap.set(chat.offerId, chat.id));
  }

  @Mutation
  addChat(chat: any) {
    this.chats.push(chat);
    this.offerMap.set(chat.offerId, chat.id);
  }

  @Action({ rawError: true })
  async loadChats(userId: string): Promise<void> {
    //TODO: reload chats every minute or something?
    const chats = await ChatService.getChats(userId);
    this.context.commit('setChats', chats);
  }

  @Action({ rawError: true })
  async createChat(chat: any): Promise<void> {
    const newChat = await ChatService.createChat(chat);
    await this.context.dispatch('addChat', newChat);
  }
}

export default ChatModule;
