import { apiConfig } from '@/config/api.config';
import axios from 'axios';
import authHeader from './auth-header';
import { addFilter } from './filter-helper';

const API_URL = `${apiConfig.host}api/chats/`;

class ChatService {
  async getChats(userId: string) {
    const url = addFilter(API_URL, 'userId', userId);
    const response = await axios.get(url, { headers: authHeader() });
    return response.data;
  }

  async getChat(chatId: string) {
    const url = API_URL + chatId;
    const response = await axios.get(url, { headers: authHeader() });
    return response.data;
  }

  async createChat(chat: any) {
    const response = await axios.post(API_URL, chat, { headers: authHeader() });
    return response.data;
  }
}

export default new ChatService();
