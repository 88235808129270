

























































import { VForm } from "@/plugins/vuetify";
import { IResponse } from "@/store/response.interface";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AuthService from "@/services/AuthService";

const Auth = namespace("Auth");
const ChatModule = namespace("ChatModule");

@Component
export default class Login extends Vue {
  private user: any = { email: '', password: '' };
  private errorMessage?: string = '';
  private loading: boolean = false;
  private showPassword: boolean = false;
  private resetSend = false;

  private rules = {
    required: [(val:any) => (val || '').length > 0 || 'Required.'],
    required2: [(value:any) => !!value || 'Required.'],
  } 
  
  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Action
  private checkLogin!: () => Promise<boolean>;

  @Auth.Action
  private login!: (data: any) => Promise<IResponse>;

  @ChatModule.Action
  private loadChats!: (userId: string) => Promise<void>;

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async created() {
    if (await this.checkLogin()) {
      this.$router.push('/profile');
    }
  }

  async pullChats(userId : string) {
    setInterval(function(self: any, userId: string) {
      self.loadChats(userId);
    }, 20000, this, userId);
  }

  async handleLogin() {
    if(this.form.validate()) {
      this.loading = true;
      const response = await this.login(this.user);
      if(response.succeeded) {
        await this.pullChats(response.data.userId);
        this.$router.push('/profile');
      } else {
        this.errorMessage = response.errorMessage;
      }
      this.loading = false;
    }
  }

  async requestPasswordReset() {
    await AuthService.requestPasswordReset(this.user.email);
    this.resetSend = true;
  }

}
