







import { Component, Vue } from 'vue-property-decorator';
import UserService from '@/services/UserService';

@Component
export default class Home extends Vue {
  private title = 'Welcome to MEDCOMPLEX';
  private content = `Denny erklärt die Welt...`;

  mounted() {
    //   UserService.getPublicContent().then(
    //     (response) => {
    //       this.content = response.data;
    //     },
    //     (error) => {
    //       this.content =
    //         (error.response && error.response.data) ||
    //         error.message ||
    //         error.toString();
    //     }
    //   );
  }
}
