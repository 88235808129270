import Vue from 'vue';
import Vuex from 'vuex';

import Auth from './modules/auth.module';
import UserModule from './modules/user.module';
// import DashboardModule from './modules/dashboard.module';
// import QuestionModule from './modules/question.module';
import OfferGalleryModule from './modules/offer-gallery.module';
import ChatModule from './modules/chat.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    UserModule,
    // DashboardModule,
    // QuestionModule,
    OfferGalleryModule,
    ChatModule,
  },
});
