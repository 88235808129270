















































































import { VForm } from "@/plugins/vuetify";
import { IResponse } from "@/store/response.interface";
import { Component, Vue } from "vue-property-decorator";
import Dsgvo from '@/components/Register/Dsgvo.vue';
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component({ 
  components: { Dsgvo } 
})
export default class Register extends Vue {
  private user: any = { firstname: '', lastname: '', email: '', password: '' };
  private loading: boolean = false;
  private showPassword: boolean = false;
  private errorMessage?: string = '';
  private submitted: boolean = false;
  private submitDisabled: boolean = true;
  private rules = {
    required: [(val:any) => (val || '').length > 0 || 'Required.'],
  }

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Action
  private register!: (data: any) => Promise<IResponse>;

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  mounted() {
    if (this.isLoggedIn) {
      this.$router.push('/profile');
    }
  }

  async handleRegister() {
    this.loading = true;
    if(this.form.validate()) {
      const response = await this.register(this.user);
      if(response.succeeded) {
        this.submitted = true;
      } else {
        this.errorMessage = response.errorMessage;
      }

    }
    this.loading = false;
  }
}
