export function addFilter(url: string, query: string, value: string): string {
  if (!value) {
    return url;
  }
  if (url.endsWith('/')) {
    url = url.slice(0, -1) + '?';
  } else {
    url += '&';
  }
  url += `filter[${query}]=${value}`;
  return url;
}
