import { apiConfig } from '@/config/api.config';
import axios from 'axios';
import authHeader from './auth-header';
import { addFilter } from './filter-helper';

const API_URL = `${apiConfig.host}api/offers/`;

class OfferService {
  async getOffers(filter: any) {
    let url = API_URL;
    url = addFilter(url, 'userId', filter.userId);
    url = addFilter(url, 'priceRange', filter.price.toString());
    url = addFilter(url, 'disciplines', filter.disciplines.toString());
    url = addFilter(url, 'distanceRange', filter.distanceRange.toString());
    const response = await axios.get(url, { headers: authHeader() });
    return response.data;
  }

  async getOffer(id: string) {
    const response = await axios.get(`${API_URL}${id}`, {
      headers: authHeader(),
    });
    return response.data;
  }

  async upsertOffer(offer: any) {
    const response = await axios.post(API_URL, offer, {
      headers: authHeader(),
    });
    return response.data;
  }

  async deleteOffer(id: string) {
    await axios.delete(`${API_URL}${id}`, {
      headers: authHeader(),
    });
  }

  async saveImages(id: string, images: any) {
    let formData = new FormData();
    images.forEach((image: any) => {
      formData.append('files', image);
    });
    const response = await axios.post(`${API_URL}${id}/images`, formData, {
      headers: authHeader(),
    });
    return response.data;
  }
}

export default new OfferService();
