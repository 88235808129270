import { apiConfig } from '@/config/api.config';
import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${apiConfig.host}api/users/`;

class UserService {
  async getUsers() {
    const response = await axios.get(API_URL, { headers: authHeader() });
    return response.data;
  }

  async getUser(id: string) {
    const response = await axios.get(`${API_URL}${id}`, {
      headers: authHeader(),
    });
    return response.data;
  }

  async updateUser(user: any) {
    const response = await axios.post(API_URL, user, { headers: authHeader() });
    return response.data;
  }

  async deleteUser(id: string) {
    await axios.delete(`${API_URL}${id}`, {
      headers: authHeader(),
    });
  }
}

export default new UserService();
